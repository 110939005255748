<template>
  <div class="container">
    <PublicHeader :totalName="$t('bank').editTitle" />
    <div class="top">
      <van-field
        v-model="form.CardUserName"
        :label="$t('bank').accountName"
        :placeholder="$t('bank').placeholder1"
      />
      <van-field
        v-model="bankName"
        :label="$t('bank').opening"
        :placeholder="$t('bank').placeholder2"
        right-icon="wap-nav"
        @click-right-icon="show = true"
      />
      <van-field
        v-model="form.OpenBank"
        :label="$t('bank').point"
        :placeholder="$t('bank').placeholder3"
      />
      <van-field
        v-model="form.CardNo"
        :label="$t('bank').card"
        :placeholder="$t('bank').placeholder4"
        onpaste="return false"
        oncontextmenu="return false"
      />
      <i></i>
    </div>
    <div v-if="basicConfig.IsShowCurrency">
      <van-divider dashed>{{ $t('bank').DigitalCurrency }}</van-divider>
      <van-field
        v-model="form.USDT"
        label="USDT"
        :placeholder="$t('bank').usdt"
      />
      <van-field v-model="form.BTC" label="BTC" :placeholder="$t('bank').btc" />
      <van-field v-model="form.ETH" label="ETH" :placeholder="$t('bank').eth" />
      <i></i>
    </div>

    <van-button size="large" @click="handleSubmit">{{
      $t('bank').submit
    }}</van-button>
    <van-popup v-model="show" position="bottom">
      <van-picker
        :title="$t('public').select"
        :confirm-button-text="$t('public').confirm"
        :cancel-button-text="$t('public').cancel"
        show-toolbar
        :default-index="defaultIndex"
        value-key="Name"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      value: '',
      show: false,
      columns: [],
      bankName: '',
      defaultIndex: 0,
      form: {
        CardUserName: '',
        OpenOfBankId: null,
        OpenBank: '',
        CardNo: '',
        USDT: '',
        BTC: '',
        ETH: '',
        CardETHUserName: '',
      },
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    onConfirm(value) {
      this.bankName = value.Name
      this.form.OpenOfBankId = value.Id
      this.show = false
    },
    async getOpenBank() {
      this.columns = await userApi.getOpenBank()
      this.getUserInfo()
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      for (let i in this.form) {
        this.form[i] = res[i]
      }
      this.columns.forEach((item, index) => {
        if (item.Id === this.form.OpenOfBankId) {
          this.defaultIndex = index
          this.bankName = item.Name
          return
        }
      })
      this.IsBindBank = res.IsBindBank
    },
    async handleSubmit() {
      let form = { ...this.form }

      if (form.CardUserName === '') {
        this.$toast({
          message: this.$t('bank').placeholder1,
          position: 'bottom',
        })
        return
      }
      if (form.OpenOfBankId === '') {
        this.$toast({
          message: this.$t('bank').placeholder2,
          position: 'bottom',
        })
        return
      }
      // if (form.OpenBank === '') {
      //   this.$toast({
      //     message: this.$t('bank').placeholder3,
      //     position: 'bottom'
      //   })
      //   return
      // }
      // if (form.CardNo === '') {
      //   this.$toast({
      //     message: this.$t('bank').placeholder4,
      //     position: 'bottom',
      //   })
      //   return
      // }
      if (this.IsBindBank) {
        await userApi.updateBank(form)
      } else {
        await userApi.createBank(form)
      }

      this.$toast.success(this.$t('public').SubmittedSuccessfully)
      this.$router.go(-1)
    },
  },
  mounted() {
    this.getOpenBank()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #fae39c
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .van-field
    margin 0
    background none
    >>> .van-field__label
      color #ebe5cd
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .van-button
    width calc(100% - 30px)
    border none
    margin 21px 15px 23px
    color #000
    font-size 14px
    height 44px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important
  .van-picker
    color #000
</style>
